import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import useStringBuilder from './use-string-builder-state'
import StringBuilderTemplate from './string-builder-template'
import StrandsSelector from './strands-selector'
import get from 'lodash/get'

export default function StringBuilder2(props) {
	const {
		// strands,
		servings,
		settings,
	} = props.pageContext
	const [state] = useStringBuilder()

	// Redirect to beginning if a step was missed
	useEffect(() => {
		if (!state.string){
			let prev = document.location.pathname.split(`/`)
			if(!prev[prev.length - 1]){
				prev.pop()
			}
			prev.pop()
			navigate(prev.join(`/`))
		}
	}, [])

	return (
		<StringBuilderTemplate
			settings={settings}
			stepNumber={2}
			title={`Choose your custom ${get(state, `string.id`)} String Style`}
			enableNext={!!(state.strand1 && state.strand2 && state.serving)}
		>
			<StrandsSelector servings={servings} />
		</StringBuilderTemplate>
	)
}